.custom-input-group{
    display: flex;
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 2px 0 #00000008;
    overflow: hidden;
}
.custom-input-all{
    padding: 8px 24px;
    width: 13rem;
    color: #495057;
    min-width: 250px;
    font-size: 16px;
    border: none;
    background-clip: padding-box;
    background-image: url("search.png");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 15px;
}
.custom-input-all:focus-visible {
    outline: none!important;
}
.input-label{
    font-size: 15px;
    color: #313649;
    letter-spacing: -0.02em;
}
